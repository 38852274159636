import React from 'react'
import { withRouteData, Head } from 'react-static'
import Markdown from 'react-markdown'

const Home = ({ page }) => {
  return (
    <React.Fragment>
      <Head key="page-head">
        <title>{page.data.title}</title>
        <meta name="description" content={`${page.data.description}`} />
      </Head>
      <div className='page-content'>
        <Markdown source={page.content} escapeHtml={false} />
        <img style={{ width: '1000px', marginBottom: '40px' }} src={`${page.data.image}?nf_resize=fit&w=1000`} alt='Picture of a home built by pineview builders' />
      </div>
    </React.Fragment>
  )
}

export default withRouteData(Home)
